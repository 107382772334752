import { FullPageElement, html, unsafeHTML, css, formCss } from 'Elements';
import { Fetcher, UrlParams } from 'Utils';
import App from 'App';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import config from './config.js';
import './detail.js';

const tableCss = css`
  table {
    width:100%;
    border-collapse: collapse;
    box-sizing: border-box;
    font-size:0.9em;
    text-align:left;
  }

  table.disabled {
    pointer-events:none;
    filter: grayscale(100%);
    opacity:0.5;
  }

  table thead {
    font-weight:bold;
    height:40px;
    align-items:center;
    background-color: var(--sl-color-neutral-100);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 8px -2px;
    top:0px;
    position:sticky;
    z-index:10;
  }

  table th {
    border: 1px solid var(--sl-color-neutral-200);
    padding-left: 10px;
  }

  table tr {
    box-sizing: border-box;
    border-radius:5px;
  }

  table td {
    padding: 7px;
    border:1px solid var(--sl-color-neutral-200);
  }

  table td.right {
    padding-right:10px;
    text-align:right;
  }

  table td.icon {
    width:30px;
    text-align:center;
    font-size:1.8em;
    font-weight:bold;
    padding:0;
  }

  table td.icon m-icon {
    opacity:1;
    transition: none;
  }

  table tbody tr:nth-child(even) td {
    
  }

  table tbody tr:nth-child(odd) td {
    background-color: var(--table-list-odd-background-color)
  }

  table tbody tr.line {
    cursor:pointer;
  }

  table tbody tr.line:hover {
    outline:2px ridge var(--sl-color-primary-200);
    outline-offset: -1px;
  }

  table tbody tr.selected {
    outline:2px ridge var(--sl-color-primary-500);
    outline-offset: -1px;
  }

  table tbody tr:hover td.icon m-icon {
    opacity:1;
  }

  table tr {
    padding:0;
  }

  table td {
    padding:0px;
    padding-left: 7px;
    padding-bottom: 0px;
  }

  td.grouper {
    background-color: var(--sl-color-neutral-100) !important;
  }

  .group-header {
    background-color: var(--table-list-group-background-color);
    font-size: 1em;
    padding:5px;
    padding-right:10px;
    padding-left: 10px;
    color:white;
    text-transform: capitalize;
    display:inline-block;
    border-radius: 20px;
    width:200px;
    margin-top:20px;
    margin-bottom:20px;
    margin-left:0px;
  }

  .events {
    display:flex;
    flex-wrap:wrap;
    gap:5px;
    padding:5px;
  }

  .events m-icon {
    font-size:1.4em;
  }

  .events sl-tag::part(base) {
    padding-left:3px;
    font-size:0.9em;
  }

  .events sl-tag m-icon {
    margin-right:5px;
    font-size:1.7em;
    padding-left:0px;
  }

  .pagesnav {
    display:flex;
    justify-content:space-between;
    align-items:baseline;
    gap:10px;
    margin:5px;
    min-height:30px;
  }

  .pagesnav .title {
    font-size:0.9em;
  }

  .empty {
    display:flex;
    width:100;
    justify-content:center;
    text-align:center;
    gap:10px;
    padding:20px;
    flex-direction:column;
  }

  .empty m-icon {
    font-size:3em;
  }

  em {
    font-style: normal;
    background-color: var(--sl-color-yellow-200);
    padding-left:2px;
    padding-right:2px;
  }
  
  .center {
    width:100%;
    height:40vh;
    text-align:center;
  }

  @media (max-width: 670px) {
    .header {
      flex-direction: column;
      gap: 10px;
    }
  }

`
class LogsTable extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      formCss, // CSS externe pour les styles de base
      tableCss // CSS spécifique pour le tableau
    ];
  }

  static get translations() {
    return [
      super.translations,
      config.translations,
      {
        english: {
          translation: {
            domain:'Domain',
            registrar:'Registrar',
            empty: 'No domain available',
          }
        },
        french: {
          translation: {
            domain:'Domaine',
            registrar:'Registrar',
            empty: 'Aucun domaine disponible',
          }
        }
      }
    ];
  }

  static get properties() {
    return {
      items: { type: Array },
      displayedItems: { type: Array }, // Gestion des éléments affichés pour le lazy loading
      groupBy: { type: String },
    };
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.items = null;
    this.displayedItems = [];
    this.page = parseInt(UrlParams.get('p', 1));
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.setDayJsLocale();
    window.addEventListener('language-changed', this.onLanguageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  setDayJsLocale() {
    dayjs.locale(App.config.lg);
  }

  async onLanguageChanged() {
    this.setDayJsLocale();
  }

  async visibleCallback() {
    super.visibleCallback();
    if (this.items === null) {
      await this.reloadData(); // Charger les données lors de l'affichage
    }
  }

  async reloadData() {
    if (this.loading) return;
    this.loading = true;
    this.loader = this.loader || this.parent.qs('sl-progress-bar');
    this.loader.style.visibility = 'visible';
    let url = this.apiEndpoint;
    const params = new URLSearchParams(window.location.search);
    const searchParams = Object.fromEntries(params.entries());
    this.timeoutLongSearch = setTimeout(async () => {
      this.loader.style.visibility = 'hidden';
      this.waiting = true;
      await this.requestUpdate();
      this.waiting = false;
    }, 500);

    console.log('afnic table reloadData');
    const response = await Fetcher.post(url, searchParams);
    clearTimeout(this.timeoutLongSearch);
      
    this.items = response?.data || [];
    this.meta = response?.meta;
    this.loading = false;
    this.loader.style.visibility = 'hidden';
  }

  groupEvents(items) {
    const grouped = {};
    items.forEach(event => {
      const createdDate = dayjs(event.date);
      let key;

      if (this.groupBy === 'day') {
        key = createdDate.format('dddd DD/MM/YYYY');
      } else if (this.groupBy === 'week') {
        const startOfWeek = createdDate.isoWeek();
        key = `Semaine ${startOfWeek}: ${createdDate.startOf('isoWeek').format('DD/MM')} - ${createdDate.endOf('isoWeek').format('DD/MM')}`;
      } else if (this.groupBy === 'month') {
        key = createdDate.format('MMMM YYYY');
      }

      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(event);
    });
    return grouped;
  }


  panelOpen(ev, item) {
    this.modalDetail = this.modalDetail || this.qs('probe-afnic-detail');
    UrlParams.set(this.urlVar, item.id);
    this.modalDetail.item = null;
    this.modalDetail.item = item;
  }

  panelClose(ev) {
    UrlParams.del(this.urlVar);
  }

  pick(from, s) {
    return s
        .replace(/\[([^\[\]]*)\]/g, ".$1.")
        .split(".")
        .filter((t) => t !== "")
        .reduce((prev, cur) => prev && prev[cur], from)
  }

  renderColumnDomain(item) {
    return (item.highlight?.cdomain || item.highlight?.domain || item.cdomain || item.domain || 'N/A').toString();
  }

  renderTableHeader() {
    return html`
      <thead>
        <tr>
          <th>${this._tl('domain')}</th>
            <th width="300" align="center">${this._tl('registrar')}</th>
            <th width="120" align="center">Importé</th>
            <th width="120" align="center">Créé</th>
            <th width="120" align="center">Expire</th>
            <th width="50" align="center">Whois</th>
        </tr>
      </thead>
    `;
  }

  renderItems() {
    if (this.loading) {
      return ''; // Afficher un indicateur de chargement ici si nécessaire
    }

    if (!this.items?.length) {
      return html`
        <div class="empty">
          <m-icon name="troubleshoot" size="big"></m-icon>
          <div>${this._tl('empty')}</div>
        </div>
      `;
    }

    const groupedItems = this.groupEvents(this.items); // Utiliser les éléments affichés

    return html`
      <table ?disabled=${this.loading}>
        ${this.renderTableHeader()}
        <tbody>
          ${Object.keys(groupedItems).map(group => html`
            <tr>
              <td colspan="100" class="grouper">
                <div class="group-header">${group}</div>
              </td>
            </tr>
            ${groupedItems[group].map(item => {
              return html`
              <tr class="line" @click=${ev => this.panelOpen(ev, item)}>
                <td>${unsafeHTML(this.renderColumnDomain(item))}</td>
                <td>${item.whois?.registrar || 'N/A'}</td>
                <td align="center">${dayjs(item.date).format('DD/MM/YYYY HH:mm')}</td>
                <td align="center">${item.whois?.creationDate ? dayjs(item.whois?.creationDate).format('DD/MM/YYYY HH:mm') : dayjs(item.date).format('DD/MM/YYYY HH:mm')}</td>
                <td align="center">${item.whois?.expirationDate ? dayjs(item.whois?.expirationDate).format('DD/MM/YYYY HH:mm') : 'N/A'}</td>
                <td align="center">${item.whois?.raw?.length ? 'Y' : ''}</td>
              </tr>
            `})}
          `)}
        </tbody>
      </table>
    `;
  }

  renderPagesNav() {
    const totalCount = this.meta?.totalCount || 0;
    const totalText = (totalCount >= 1 ? `${this._tl('domain')}s` : this._tl('domain')).toLowerCase();
    return html`
      <div class="pagesnav">
        <div class="title">${totalCount} ${totalText}</div>
        <data-paginator pages=${this.meta?.totalPages} value=${this.page} @change=${this.onPaginatorChange}></data-paginator>
      </div>
    `;
  }

  onPaginatorChange(ev) {
    this.page = ev.detail;
    UrlParams.set('p', this.page);
    this.reloadData();
  }

  render() {
    return html`
      ${this.renderPagesNav()}
      ${this.waiting 
        ? html`<div class="center"><sl-spinner style="font-size: 5rem;"></sl-spinner><br/><br/><br/>${this._tl('please_wait')}</div>`
        : this.renderItems()
      }
      <probe-afnic-detail @close=${this.panelClose}></probe-afnic-detail>
    `;
  }
}

customElements.define('probe-afnic-table', LogsTable);
