import { PageElement, html, unsafeHTML, css, formCss } from 'Elements';
import { Session } from 'Utils';

import './table.js';
import './filters.js';
import './chart.js';

class EventsPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        sl-badge {
          font-size:0.5em;
          position:absolute;
          top:5px;
          margin-left:10px;
          cursor:pointer;
        }

        data-search-toolbar {
          margin-bottom:10px;
        }
      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'AFNIC',
            table:'Data',
            chart:'Graph',
            help_text: `
              Source of data: <a href="https://www.afnic.fr/produits-services/services-associes/donnees-partagees/" target="_blank">AFNIC</a> + whois.
              <br/><br/>Domains are retrieved every 3 hours starting from midnight. Then, the whois data is collected 
              sequentially at a rate of one domain per second.`
          }
        },
        french:{
          translation: {
            title:'AFNIC',
            table:'Données',
            chart:'Graphique',
            help_text: `
              Source des données: <a href="https://www.afnic.fr/produits-services/services-associes/donnees-partagees/" target="_blank">AFNIC</a> + whois.
              <br/><br/>Les domaines sont récupérés toutes les 3h heures à partir de minuit. Ensuite, la récupération des whois
              se fait de manière séquentielle au rythme d\'un domaine par seconde.`,
          },
        }
      }
    ]
  }

  static get properties() {
    return {
      loading: { type: Boolean },
      newEvents: { type: Number },
      groupBy: { type: String },
    };
  }

  constructor() {
    super();
    this.layout = 'large';
    this.socketOptions = { room:'data_afnic', event:'add' };
    this.newEvents = 0;
    this.joinRoom = this.joinRoom.bind(this);
    this.onNewEvent = this.onNewEvent.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.groupBy = 'day';
  }
  
  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('socket-reconnected', this.joinRoom);
    this.joinRoom();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('socket-reconnected', this.joinRoom);
    Session.sockets.boss.leave(this.socketOptions);
  }

  joinRoom() {
    Session.sockets.boss.onMessages(this.socketOptions, this.onNewEvent);
  }

  onNewEvent() {
    this.newEvents+=1;
  }

  reloadData() {
    this.tab = this.tab || this.qs('probe-afnic-table');
    console.log('reloadData', this.tab);

    if (this.tab) {
      this.tab.reloadData();
      if (this.tab.tagName === 'PROBE-AFNIC-TABLE') {
        this.qs('probe-afnic-chart').series = null;
      } else {
        this.qs('probe-afnic-table').items = null;
      }
    }
  }

  showHelp() {
    this.qs('#modal-help').show();
  }

  async visibleCallback() {
    super.visibleCallback();
    this.reloadData();
  }

  onPanelChange(e) {
    if (e.detail.name === 'chart') {
      this.tab = this.qs('probe-afnic-chart');
    } else {
      this.tab = this.qs('probe-afnic-table');
    }
  }

  render() {    
    return html`
      <section-header backroute="../../" micon="dns">
        <div style="position:relative;">
          ${this._tl('title')}
          ${this.newEvents
            ? html`<sl-badge variant="primary" pill pulse @click=${this.reloadData}>${this.newEvents}</sl-badge>`
            : ''
          }
        </div>
        <m-icon name="help" slot="right" clickable @click=${this.showHelp}></m-icon>
      </section-header>

      <sl-progress-bar style="--height: 2px;" indeterminate></sl-progress-bar>

      <data-search-toolbar .parent=${this}>
        <probe-afnic-filters .parent=${this}></probe-afnic-filters>
      </data-search-toolbar>

      <tab-group @change=${this.onPanelChange}>
        <sl-tab slot="nav" panel="table" class="bold">${this._tl('table')}</sl-tab>
        <sl-tab slot="nav" panel="chart" class="bold">${this._tl('chart')}</sl-tab>

        <sl-tab-panel name="table">
          <probe-afnic-table groupBy=${this.groupBy} .parent=${this}></probe-afnic-table>
        </sl-tab-panel>

        <sl-tab-panel name="chart">
          <probe-afnic-chart .parent=${this}></probe-afnic-chart>
        </sl-tab-panel>
      </tab-group>

      <modal-drawer id="modal-help" label="AFNIC">
        <p>${unsafeHTML(this._tl('help_text'))}</p>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('close')}</sl-button>
      </modal-drawer>
    `;
  }
}

customElements.define('page-probe-afnic', EventsPage);
