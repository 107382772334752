import { PageElement, html, css } from 'Elements';
import { Fetcher } from 'Utils';
import config from './config.js';
import dayjs from 'dayjs';

class AfnicChart extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .center {
          width:100%;
          height:40vh;
          text-align:center;
        }

        .chart_buttons {
          display:flex;
          gap:10px;
          padding-right:10px;
        }

        .chart_buttons sl-button::part(label) {
          display: flex;
          line-height: initial;
          align-items: center; 
        }

        .chart_buttons m-icon {
          font-size:1.5em;
        }

        sl-switch {
          margin-top:5px;
        }

        sl-switch::part(label) {
          text-transform: initial;
          font-size:0.9em;
        }

      `
    ]
  }

  static get properties() {
    return {
      parent: { type: Object },
      loading: { type: Boolean },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            domain_standard:'Standard domain names',
            domain_punnycode:'Punnycode domain names',
          }
        },
        french:{
          translation: {
            domain_standard:'Domaines standards',
            domain_punnycode:'Domaines punnycode',
          }
        }
      }
    ]
  }

  constructor() {
    super();
  }

  async visibleCallback() {
    super.visibleCallback();
    if (this.series === null) {
      await this.reloadData(); // Charger les données lors de l'affichage
    }
  }

  async reloadData() {

    if (this.loading) return;

    this.loader = this.loader || this.parent.qs('sl-progress-bar');
    this.loader.style.visibility = 'visible';
    this.loading = true;

    let url = config.apiEndpoint+'?type=stats'
    const params = new URLSearchParams(window.location.search);
    const searchParams = Object.fromEntries(params.entries());
    searchParams.stats = true;
    this.series = null;
    this.labels = null;
    const response = await Fetcher.post(url, searchParams);
    if (!response) {
      this.loader.style.visibility = 'hidden';
      this.loading = false;
      return;
    }
    this.series = this.formatSeries(response.data.series);
    this.labels = this.formatCategories(response.data.categories, response.data.granularity);
    this.colors = response.data.colors || ['#2581bf', '#ff0000'];
    this.loader.style.visibility = 'hidden';
    this.loading = false;
  }

  formatSeries(series) {
    for (const serie of series) {
      serie.name = this._tl(serie.name);
    }
    return series;
  }

  formatCategories(categories, granularity) {
    if (granularity === 'hour')   return categories.map(c => dayjs(c).format('DD MMM HH:mm'));
    if (granularity === 'day')    return categories.map(c => dayjs(c).format('DD MMM'));
    if (granularity === 'month')  return categories.map(c => dayjs(c).format('MMM YYYY'));
  }

  onChartTypeUpdate(ev) {
    const target = ev.target.closest('sl-radio-group');
    const gtype = target.value;
    if (!gtype) return;

    const chart = this.qs('apex-chart');

    if (gtype === 'bar') {
      chart.type = 'bar';
      chart.fillType = 'gradient';
      chart.strokeWidth = 0;
      chart.gradientType = 'horizontal';      
      return;
    }

    if (gtype === 'line') {
      chart.type = 'line';
      chart.fillType = 'solid';
      chart.strokeWidth = 2;
      return;
    }

    if (gtype === 'area') {
      chart.type = 'area';
      chart.gradientType = 'vertical';
      chart.strokeWidth = 2;
      chart.fillType = 'gradient';
      return;
    }

    chart.stacked = this.qs('sl-switch').checked;
  }

  onChartStackedUpdate(ev) {
    const chart = this.qs('apex-chart');
    chart.stacked = ev.target.checked;
  }

  onChartDataLabelUpdate(ev) {
    const chart = this.qs('apex-chart');
    chart.dataLabelsEnabled = ev.target.checked;
  }

  renderChart() {
    if (!this.series|| !this.labels) {
      return '';
    }
    return html`
      <div>
        <section-header size="medium">
          <div slot="right">
            <div class="chart_buttons">
              <sl-switch size="small" checked @sl-change=${this.onChartStackedUpdate}>Cumul</sl-switch>
              <sl-switch size="small" checked @sl-change=${this.onChartDataLabelUpdate}>Chiffres</sl-switch>
              <sl-radio-group size="small" name="type" value="bar" @sl-change=${this.onChartTypeUpdate}>
                <sl-radio-button checked value="bar"><m-icon name="bar_chart"></m-icon></sl-radio-button>
                <sl-radio-button value="line"><m-icon name="stacked_line_chart"></m-icon></sl-radio-button>
                <sl-radio-button value="area"><m-icon name="area_chart"></m-icon></sl-radio-button>
              </sl-radio-group>           
            </div>
          </div>
        </section-header>
        <div style="height:15px"></div>
        <apex-chart type="bar" style="height:50vh;" id="tickets-distribution"
          strokeWidth=0
          gradientType="horizontal"
          stacked
          dataLabelsEnabled
          animationsEnabled
          legendPosition="top"
          .series=${this.series}
          .labels=${this.labels}
          .colors=${this.colors}
        ></apex-chart>
      </div>
    `;
  }

  render() {
    return html`
      ${this.loading 
        ? html`<div class="center"><sl-spinner style="font-size: 5rem;"></sl-spinner><br/><br/><br/>${this._tl('please_wait')}</div>`
        : this.renderChart()
      }
    `;
  }
}

customElements.define('probe-afnic-chart', AfnicChart);
