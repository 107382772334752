import { FullPageElement, html, css, formCss } from 'Elements';
import { UrlParams } from 'Utils';
import config from './config.js';

class Filters extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        :host {
          margin-top:5px;
        }

        sl-option::part(base) {
          line-height:initial;
          padding:0px;
          font-size:0.95em;
        }

        sl-option m-icon {
          font-size:1.2em;
          float:left;
          margin-right:5px;
        }

        .filters {
          display:flex;
          align-items:center;
          justify-content:space-around;
          gap:5px;
          width:100%;
        }

        .filters .left {
          display:flex;
          justify-content: space-between;
          align-items:baseline;
          width:100%;
        }

        .filters .right {
          display:flex;
          align-items:center;
          justify-content:flex-end;
          gap:5px;
          width:100%;
        }

        @media (max-width: 670px) {
          .filters {
            flex-direction: column;
            gap: 10px;
          }
        }

      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      config.translations,
      {
        english:{
          translation: {
            placeholder_from:'From',
            placeholder_level:'Level',
          }
        },
        french:{
          translation: {
            placeholder_from:'Provenance',
            placeholder_level:'Niveau',
          },
        }
      }
    ]
  }

  constructor() {
    super();
    this.period = UrlParams.get('dp');

    const levels = UrlParams.get('level');
    this.selectedLevels = levels ? levels.replace(',', ' ') : '';

    const froms = UrlParams.get('from');
    this.selectedFroms = froms ? froms.replace(',', ' ') : '';
    
  }

  onLevelsChange(ev) {
    this.selectedLevels = this.qs('sl-select[name="levels"]').value.join(',');
    this.selectedLevels.length ? UrlParams.set('level', this.selectedLevels) : UrlParams.del('level');
    UrlParams.del('p');
    this.reloadData();
  }

  onFromsChange(ev) {
    this.selectedFroms = this.qs('sl-select[name="froms"]').value.join(',');
    this.selectedFroms.length ? UrlParams.set('from', this.selectedFroms) : UrlParams.del('from');
    UrlParams.del('p');
    this.reloadData();
  }

  onPunnycodeChange(ev) {
    console.log(ev);
    UrlParams.del('p');
    this.reloadData();
  }

  reloadData() {
    this.parent.reloadData();
  }

  render() {    
    return html`
      <div class="filters">
        <div class="right">
          <sl-switch name="punnycode" @sl-change=${this.onPunnycodeChange} ?checked=${this.punnycode}>Punnycode</sl-switch>

        </div>
      </div>
    `;
  }
}

customElements.define('probe-afnic-filters', Filters);
