import { PageElement, html, css } from 'Elements';

class LogsDetailDrawer extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
      `
    ]
  }

  static get properties() {
    return {
      item: { type: Object },
    };
  }

  constructor() {
    super();
    this.item = null;
  }

  async updated(changedProperties) {
    if (changedProperties.has('item')) {
      if (this.item) {
        this.qs('modal-drawer').show();
      }
    }
  }

  render() {
    if (!this.item) return '';
    return html`
      <modal-drawer label="Detail">
        <box-json .value=${this.item}></box-json>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('close')}</sl-button>
      </modal-drawer>
    `;
  }
}

customElements.define('probe-afnic-detail', LogsDetailDrawer);
